<template>
  <div>
    <lang-list/>
  </div>
</template>

<script>
import LangList from '@/components/LangList.vue';

export default {
  components: {
    LangList,
  },
};
</script>